import React from 'react'
import { Link } from 'gatsby'

import BlogSearch from './BlogSearch'
import './PostCategoriesNav.css'

const EventCategoriesNav = ({ categories, enableSearch }) => (
  <div className="PostCategoriesNav">
    <div className="categoriesLinks">
      <Link className="NavLink myBtn" exact="true" to={`/events/`}>
        All
      </Link>
      {categories.map((category, index) => (
        <Link
          exact="true"
          className="NavLink myBtn"
          key={category.title + index}
          to={category.slug}
        >
          {category.title}
        </Link>
      ))}
    </div>
    {enableSearch && <BlogSearch />}
  </div>
)

export default EventCategoriesNav
